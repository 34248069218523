<template>
  <div>
    <!-- <el-tabs v-model="activeName" @tab-click="tabClickN" class="text_table">
      <el-tab-pane label="营养知识库" name="1"></el-tab-pane>
      <el-tab-pane label="营养品库" name="2"></el-tab-pane>
      <el-tab-pane label="营养知识分类" name="3"></el-tab-pane>
      <el-tab-pane label="营养品分类设置" name="4"></el-tab-pane>
    </el-tabs> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "",
    };
  },
  watch: {
    $route(a) {},
  },
  created() {
  },
  mounted() {
    // 路由判定
    // if (this.$route.query.type) {
    //   this.activeName = this.$route.query.type;
    // } else {
    //   setTimeout(() => {
    //     if (!this.$store.getters.permissionsName("营养知识")) {
    //       this.activeName = "1";
    //     } else if (!this.$store.getters.permissionsName("营养品")) {
    //       this.activeName = "2";
    //     }else if (!this.$store.getters.permissionsName("营养知识分类")) {
    //       this.activeName = "3";
    //     }else if (!this.$store.getters.permissionsName("营养品分类设置")) {
    //       this.activeName = "4";
    //     }
    //     this.tabClickN();
    //   }, 1000);
    // }
  },
  methods: {
    // tabClickN() {
    //   if (this.activeName == "") {
    //     let time = setInterval(() => {
    //       if (!this.$store.getters.permissionsName("营养知识")) {
    //         this.activeName = "1";
    //         clearInterval(time);
    //         this.create();
    //       } else if (!this.$store.getters.permissionsName("营养品")) {
    //         this.activeName = "2";
    //         clearInterval(time);
    //         this.create();
    //       } else if (!this.$store.getters.permissionsName("营养知识分类")) {
    //         this.activeName = "3";
    //         clearInterval(time);
    //         this.create();
    //       } else if (!this.$store.getters.permissionsName("营养品分类设置")) {
    //         this.activeName = "4";
    //         clearInterval(time);
    //         this.create();
    //       }
    //     }, 200);
    //   } else {
    //     this.create();
    //   }
    // },
    //  create() {
    //   if (this.activeName == "1") {
    //     this.$router.push({
    //       path: "/nutritionSet/knowledgeList",
    //       query: { type: 1 },
    //     });
    //   } else if (this.activeName == "2") {
    //     this.$router.push({
    //       path: "/nutritionSet/nutritionLibrary",
    //       query: { type: 2 },
    //     });
    //   }else if (this.activeName == "3") {
    //     this.$router.push({
    //       path: "/nutritionSet/knowledgeSet",
    //       query: { type: 3 },
    //     });
    //   }else if (this.activeName == "4") {
    //     this.$router.push({
    //       path: "/nutritionSet/nutritionLibrarySet",
    //       query: { type: 4 },
    //     });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .text_table {
  padding-left: 20px;
  position: relative;
  // background-color: aquamarine;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  .el-tabs__header {
    margin-bottom: 0;
    .el-tabs__nav-wrap::after {
      background: #fff;
      margin-left: 10px;
      width: 90%;
    }
    .el-tabs__nav {
      height: 55px;
      line-height: 55px;
      font-size: 14px;
    }
    .el-tabs__item {
      font-size: 16px;
      padding: 0 25px;
      &:hover {
        color: #0055e9;
      }
    }
    .is-active {
      color: #0055e9;
    }
    .el-tabs__active-bar {
      background-color: #0055e9;
    }
  }
}
</style>